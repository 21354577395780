// MOSAIC 16:9 TEMPLATE 8
// 6 posts

@mixin small-post {
  width: 16%;
  height: $three-hundredth;

  .post {
    font-size: 1em;
  }
}

@mixin medium-post {
  width: 23%;
  height: 50%;

  .post {
    font-size: 1.25em;
  }
}

@mixin large-post {
  width: 32%;
  height: $three-hundredth-by-two;

  .post {
    font-size: 1.5em;
  }
}

@mixin largest-post {
  width: 45%;
  height: 100%;

  .post {
    font-size: 2em;
  }
}

.mosaic-16-9-template-8 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include medium-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @include medium-post;
      bottom: 0;
      left: 0;
    }

    &:nth-child(3) {
      @include largest-post;
      top: 0;
      left: 23%;
    }

    &:nth-child(4) {
      @include large-post;
      top: 0;
      right: 0;
    }

    &:nth-child(5) {
      bottom: 0;
      right: 16%;
    }

    &:nth-child(6) {
      bottom: 0;
      right: 0;
    }
  }
}
