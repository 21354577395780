@use "sass:math";

.grid-v1_0 {
  @import "../shared/defaults";
  @import "modal";
  @include display-header;
  @include origin-flag-tagboard-classic;

  .posts {
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 16vmin);

    .post {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .5);
      box-shadow: inset 0 0 0 1px rgba($black, .1);
    }

    .text {
      color: $dark-gray;
    }

    .has-media {
      color: $white;
    }

    .media-module {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    .blurred-media-module {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .media {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .text-module {
      position: absolute;
      left: 0;
      width: 100%;
      padding: .5em;
      background-color: $white;
      overflow: hidden;
    }

    .text .text-module, .chat .text-module {
      top: 0;
      height: 100%;
    }

    .has-media .text-module {
      bottom: 0;
      height: auto;
      background: linear-gradient(to top, rgba($black, .8), rgba(black, 0));
    }

    .meta-author {
      position: relative;
      font-size: .8em;
      padding-left: 2.75em;
    }

    .text .meta-author, .chat .meta-author {
      margin-bottom: .5em;
      padding-right: 1em;
    }

    .no-avatar {
      .meta-author {
        padding-left: 0;
      }

      .avatar {
        display: none;
      }
    }

    .no-author {
      .text-module {
        display: none;
      }
    }

    .avatar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      margin: auto;
      width: 2.25em;
      height: 2.25em;
      border-radius: 100%;
      box-shadow: 0 0 4px rgba($black, .5);
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .user-img {
      visibility: hidden;
      width: 100%;
      height: 100%;
    }

    .author,
    .handle {
      @include ellipsis;
      float: left;
      clear: both;
      max-width: 100%;
      line-height: 2.25;
    }

    .twitter,
    .tiktok {
      .author,
      .handle {
        line-height: 1.3;
      }

      .handle {
        font-size: .8em;
      }
    }

    .reddit .subreddit {
      float: left;
      clear: both;
      max-width: 100%;
      line-height: 1.3;
      @include ellipsis;

      + .handle {
        font-size: .8em;
        line-height: 1.3;
      }
    }

    .post-time {
      display: none;
      font-size: .8em;
      margin-bottom: .5em;
      @include ellipsis;
    }

    .timestamp {
      font-size: .8em;
    }

    .post-title {
      margin-bottom: .5em;
      font-weight: bold;
    }

    .has-media .message {
      display: none;
    }

    .origin-flag {
      top: .5em;
      right: -.3em;
    }
  }

  // POST MODAL

  .post-modal {
    .post {
      @include simple-center-align;
      width: 80%;
      height: 80%;
    }
  }

  // CUSTOMIZATIONS

  .crop-images {
    .media-module {
      background-size: cover;
    }

    .media {
      object-fit: cover;
    }
  }

  .show-blurred-images {
    .blurred-media-module {
      display: block;
    }
  }

  .gutter-none {
    .posts .origin-flag {
      right: 0;
      border-top-right-radius: 0;

      &:after {
        display: none;
      }
    }
  }

  .gutter-small {
    .posts,
    .post-container,
    .qr-module {
      padding: .3em;
    }

    .posts {
      height: calc((100% + .6em) - 16vmin);
    }
  }

  .gutter-medium {

    .posts,
    .post-container,
    .qr-module {
      padding: .6em;
    }

    .posts {
      height: calc((100% + 1.2em) - 16vmin);
    }
  }

  .gutter-large {

    .posts,
    .post-container,
    .qr-module {
      padding: 1.2em;
    }

    .posts {
      height: calc((100% + 2.4em) - 16vmin);
    }
  }

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .text-module {
      background-color: rgba($black, .5);
    }
  }

  .share-it.no-text {
    .media-module {
      float: none;
      margin: 0 auto;
    }

    .blurred-media-module {
      width: 100%;
      display: block;
    }
  }

  // GRID LAYOUTS

  .grid-cell {
    position: relative;
    float: left;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .post-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @for $i from 1 through 10 {
    .grid-columns-#{$i} {
      .grid-cell {
        width: math.div(100%, $i);
      }
    }

    .grid-rows-#{$i} {
      .grid-cell {
        height: math.div(100%, $i);
      }
    }
  }

  // MOSAIC LAYOUTS

  .mosaic .grid-cell {
    position: absolute;
  }

  .mosaic-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  // pop out modal
  .post-detail-modal {
    transition: opacity .6s ease;

    .post {
      transition: all .6s ease;
      transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .post,
  .modal-leave-active .post {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .twitch{
    .text-module{
      height:100%;
    }
    
    .message{
      font-size: 100%;
    }
  }

  // QR CODE

  .grid-cell .qr-module {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .square-placeholder {
    width: auto;
    height: 100%;
    display: none;
  }


  // 16:9
  @import "mosaic-16-9-template-1";
  @import "mosaic-16-9-template-2";
  @import "mosaic-16-9-template-3";
  @import "mosaic-16-9-template-4";
  @import "mosaic-16-9-template-5";
  @import "mosaic-16-9-template-6";
  @import "mosaic-16-9-template-7";
  @import "mosaic-16-9-template-8";

  // 4:3
  @import "mosaic-4-3-template-1";
  @import "mosaic-4-3-template-2";
  @import "mosaic-4-3-template-3";
  @import "mosaic-4-3-template-4";

  // 9:16
  @import "mosaic-9-16-template-1";
  @import "mosaic-9-16-template-2";
  @import "mosaic-9-16-template-3";
  @import "mosaic-9-16-template-4";
  @import "mosaic-9-16-template-5";
  @import "mosaic-9-16-template-6";
  @import "mosaic-9-16-template-7";
  @import "mosaic-9-16-template-8";

  // 3:4
  @import "mosaic-3-4-template-1";
  @import "mosaic-3-4-template-2";
  @import "mosaic-3-4-template-3";
  @import "mosaic-3-4-template-4";

  // 32:9
  @import "mosaic-32-9-template-1";
  @import "mosaic-32-9-template-2";

}
