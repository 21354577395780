.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 200ms ease-in-out;
  background: rgba(#000, 0.75);
}

.modal-container {
  width: calc(100% - 30px);
  max-width: 1024px;
  margin: 10vh auto;
  padding: 0;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all 200ms ease-in-out;
}

.modal-body {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.post-modal {
  .previous,
  .next {
    @include simple-center-align;
    color: rgba($white, .35);
    width: 10%;
    z-index: 999;
    height: 12vmin;
    font-size: 12vmin;
    line-height: 12vmin;
    text-align: center;
    cursor: pointer;
  }

  .previous {
    left: 0;
    right: auto;
  }

  .next {
    left: auto;
    right: 0;
  }
}
