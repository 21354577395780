// MOSAIC 4:3 TEMPLATE 3
// 12 posts

@mixin small-post {
  width: $six-hundredth;
  height: 25%;

  .post {
    font-size: 1em;
  }
}

@mixin large-post {
  width: $six-hundredth-by-two;
  height: 50%;

  .post {
    font-size: 1.3em;
  }
}

.mosaic-4-3-template-3 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      left: $six-hundredth;
    }

    &:nth-child(3) {
      @include large-post;
      top: 0;
      left: $six-hundredth-by-two;
    }

    &:nth-child(4) {
      top: 0;
      right: $six-hundredth;
    }

    &:nth-child(5) {
      top: 0;
      right: 0;
    }

    &:nth-child(6) {
      @include large-post;
      top: 25%;
      left: 0;
    }

    &:nth-child(7) {
      @include large-post;
      bottom: 0;
      left: $six-hundredth-by-two;
    }

    &:nth-child(8) {
      @include large-post;
      top: 25%;
      right: 0;
    }

    &:nth-child(9) {
      bottom: 0;
      left: 0;
    }

    &:nth-child(10) {
      bottom: 0;
      left: $six-hundredth;
    }

    &:nth-child(11) {
      bottom: 0;
      right: $six-hundredth;
    }

    &:nth-child(12) {
      bottom: 0;
      right: 0;
    }
  }
}
