// MOSAIC 16:9 TEMPLATE 7
// 9 posts

@mixin small-post {
  width: $six-hundredth;
  height: $three-hundredth;

  .post {
    font-size: 1em;
  }
}

@mixin large-post {
  width: $six-hundredth-by-two;
  height: $three-hundredth-by-two;

  .post {
    font-size: 1.3em;
  }
}

.mosaic-16-9-template-7 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include large-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      right: 50%;
    }

    &:nth-child(3) {
      top: 0;
      left: 50%;
    }

    &:nth-child(4) {
      @include large-post;
      top: 0;
      right: 0;
    }

    &:nth-child(5) {
      bottom: 0;
      left: 0;
    }

    &:nth-child(6) {
      bottom: 0;
      left: $six-hundredth;
    }

    &:nth-child(7) {
      @include large-post;
      bottom: 0;
      left: $six-hundredth-by-two;
    }

    &:nth-child(8) {
      bottom: 0;
      right: $six-hundredth;
    }

    &:nth-child(9) {
      bottom: 0;
      right: 0;
    }
  }
}
