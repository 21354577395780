// MOSAIC 16:9 TEMPLATE 1
// 14 posts

@mixin small-post {
  width: $eight-hundredth;
  height: 25%;

  .post {
    font-size: 1em;
  }
}

@mixin large-post {
  width: $eight-hundredth-by-two;
  height: 50%;

  .post {
    font-size: 1.3em;
  }
}

.mosaic-16-9-template-1 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include large-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      left: 25%;
    }

    &:nth-child(3) {
      top: 0;
      right: 50%;
    }

    &:nth-child(4) {
      @include large-post;
      top: 0;
      left: 50%;
    }

    &:nth-child(5) {
      top: 0;
      left: 75%;
    }

    &:nth-child(6) {
      top: 0;
      right: 0;
    }

    &:nth-child(7) {
      @include large-post;
      top: 25%;
      right: 50%;
    }

    &:nth-child(8) {
      @include large-post;
      top: 25%;
      right: 0;
    }

    &:nth-child(9) {
      @include large-post;
      bottom: 0;
      left: 0;
    }

    &:nth-child(10) {
      bottom: 0;
      left: 25%;
    }

    &:nth-child(11) {
      bottom: 0;
      right: 50%;
    }

    &:nth-child(12) {
      @include large-post;
      bottom: 0;
      left: 50%;
    }

    &:nth-child(13) {
      bottom: 0;
      left: 75%;
    }

    &:nth-child(14) {
      bottom: 0;
      right: 0;
    }
  }
}
