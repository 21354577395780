// MOSAIC 9:16 TEMPLATE 1
// 14 posts

@mixin small-post {
  width: 25%;
  height: $eight-hundredth;

  .post {
    font-size: 1em;
  }
}

@mixin large-post {
  width: 50%;
  height: $eight-hundredth-by-two;

  .post {
    font-size: 1.3em;
  }
}

.mosaic-9-16-template-1 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include large-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @include large-post;
      top: 0;
      right: 0;
    }

    &:nth-child(3) {
      top: 25%;
      left: 0;
    }

    &:nth-child(4) {
      bottom: 50%;
      left: 0;
    }

    &:nth-child(5) {
      @include large-post;
      top: 25%;
      left: 25%;
    }

    &:nth-child(6) {
      top: 25%;
      right: 0;
    }

    &:nth-child(7) {
      bottom: 50%;
      right: 0;
    }

    &:nth-child(8) {
      @include large-post;
      bottom: 25%;
      left: 0;
    }

    &:nth-child(9) {
      @include large-post;
      bottom: 25%;
      right: 0;
    }

    &:nth-child(10) {
      top: 75%;
      left: 0;
    }

    &:nth-child(11) {
      bottom: 0;
      left: 0;
    }

    &:nth-child(12) {
      @include large-post;
      bottom: 0;
      left: 25%;
    }

    &:nth-child(13) {
      top: 75%;
      right: 0;
    }

    &:nth-child(14) {
      bottom: 0;
      right: 0;
    }
  }
}
