// MOSAIC 3:4 TEMPLATE 1
// 9 posts

@mixin small-post {
  width: 25%;
  height: $six-hundredth;

  .post {
    font-size: 1em;
  }
}

@mixin large-post {
  width: 50%;
  height: $six-hundredth-by-two;

  .post {
    font-size: 1.3em;
  }
}

.mosaic-3-4-template-1 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include large-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @include large-post;
      top: 0;
      right: 0;
    }

    &:nth-child(3) {
      bottom: 50%;
      left: 0;
    }

    &:nth-child(4) {
      top: 50%;
      left: 0;
    }

    &:nth-child(5) {
      @include large-post;
      top: $six-hundredth-by-two;
      left: 25%;
    }

    &:nth-child(6) {
      bottom: 50%;
      right: 0;
    }

    &:nth-child(7) {
      top: 50%;
      right: 0;
    }

    &:nth-child(8) {
      @include large-post;
      bottom: 0;
      left: 0;
    }

    &:nth-child(9) {
      @include large-post;
      bottom: 0;
      right: 0;
    }
  }
}
