// MOSAIC 9:16 TEMPLATE 8
// 6 posts

@mixin small-post {
  width: $three-hundredth;
  height: 16%;

  .post {
    font-size: 1em;
  }
}

@mixin medium-post {
  width: 50%;
  height: 24%;

  .post {
    font-size: 1.25em;
  }
}

@mixin large-post {
  width: $three-hundredth-by-two;
  height: 32%;

  .post {
    font-size: 1.5em;
  }
}

@mixin largest-post {
  width: 100%;
  height: 44%;

  .post {
    font-size: 2em;
  }
}

.mosaic-9-16-template-8 {
  .grid-cell {
    @include small-post;

    &:nth-child(1) {
      @include medium-post;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @include medium-post;
      top: 0;
      right: 0;
    }

    &:nth-child(3) {
      @include largest-post;
      top: 24%;
      left: 0;
    }

    &:nth-child(4) {
      bottom: 16%;
      left: 0;
    }

    &:nth-child(5) {
      bottom: 0;
      left: 0;
    }

    &:nth-child(6) {
      @include large-post;
      bottom: 0;
      right: 0;
    }
  }
}
